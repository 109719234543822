.box {
  user-select: none;
}

.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  position: relative;
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background: black;
  cursor: pointer;
}
.uploadBox video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.uploadIcon {
  height: 75px;
  width: 75px;
  color: var(--label-color);
}

.uploadBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: top center; */
  border-radius: inherit;
  background-color: #00000046;
}

.uploadBox .editAndDelete {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.uploadBox .editAndDelete .icon {
  background-color: var(--text-color-black);
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #afa8a8;
}

.uploadBox .editAndDelete .icon svg {
  color: var(--white-color);
  vertical-align: middle;
  width: 16px;
  height: 16px;
  display: inherit;
}

@media screen and (max-width: 600px) {
  .box {
    height: 180px;
  }
}

@media screen and (max-width: 500px) {
  .box {
    height: 160px;
  }
}

@media screen and (max-width: 425px) {
  .box {
    height: 140px;
  }
}
