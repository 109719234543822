.main {
    padding-block: 20px;
    max-width: 96% !important;
    /* height: 90%; */
}

.tableMain{
    height: calc(100vh - 250px);
    overflow-y: scroll;
}
.headerContainer{
    display: flex;
    justify-content: end;
    align-items: center;
}
.paginationDiv{
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 10px;
}