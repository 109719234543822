.courseCardMain {
  box-shadow: 0px 0px 10px 0px var(--primary-color);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: var(--white-color);

  /* padding: 0; */
  padding: 0;
  position: relative;
}

.selectedCourseMain {
  background: beige !important;
}

.courseCardMain:hover {
  box-shadow: 0px 0px 20px 0px var(--secondary-color);
  cursor: pointer;
  transform: translateY(-5px);
}

.courseImageContainer {
  width: 100%;
  height: 200px;
}
.courseImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.courseDetails {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.courseDetails h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.courseDetails p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courseDetailsBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseDetailsBottomLeft {
  display: flex;
  align-items: center;
}

.courseDetailsBottomLeft p {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  margin-left: 5px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

/* .courseDetailsBottomLeft span {
  color: #af4f52;
} */

.courseDetailsBottomRight {
  display: flex;
  align-items: center;
}

.courseDetailsBottomRight p {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  margin-right: 5px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

/* .courseDetailsBottomRight span {
  color: #af4f52;
} */

.editIcon {
  position: absolute;
  right: 12px;
  top: 10px;
  background-color: var(--primary-color);
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.editIcon svg {
  color: var(--white-color);
  font-size: 20px;
}
