.pricing__card {
  position: relative;
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  --bg: var(--clr-secondary);
  --bg-alpha: var(--clr-secondary-alpha);
  min-height: 380px;
}

.pricing__card__free {
  --bg: var(--clr-primary) !important;
  --bg-alpha: var(--clr-primary-alpha) !important;
}

.pricing__card__btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
}

.pricing__card__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* gap: 1rem; */
  /* padding: 10px; */
  box-shadow: 0 0 8px var(--secondary-color);
  border-radius: 1rem;
  /* border: 2px solid var(--primary-color); */
  overflow: hidden;
}

.pricing__card__content p {
  margin: 0px !important;
  padding-block: 5px;
  font-size: 18px;
  color: var(--bg);
  /* text-transform: uppercase; */
}
.head{
  font-weight: 700;
}
.pricing__card__price {
  /* border-inline-start: 2px solid #00000030; */
  /* font-size: 1.3rem; */
  /* font-weight: 600 !important; */

}
.description{
  padding-bottom: 0px !important;
}
.title{
  font-weight: 700 !important;
  font-size: 22px !important;
  padding-right: 35px;
  color: #FFFFFF !important;
}
.content{
  padding: 10px;
}
.headWrap{
  background: transparent
  linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
  0% 0% no-repeat padding-box;
  padding: 10px;
}
@media screen and (max-width:500px) {
  .pricing__card__content p {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .pricing__card__btn {
    padding: 0px;
  }
}