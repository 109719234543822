.main {
    padding-block: 40px ;
    padding-inline: 10px;
    row-gap: 30px;
  }
  .conatiner {
    max-width: 96% !important;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .profileImg {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    overflow: hidden;
    /* background-color: blue; */
  }
  .profileImg img {
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
  }
  
  .innerRowGap{
      row-gap: 30px;
  }
  .editBtn {
    border-radius: 46px;
    width: 100%;
    display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 20px;
  }
  
  .editPasswordBtn{
      border-radius: 46px;
    display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 15px 20px;
  }
  
  @media screen and (max-width:425px) {
      .head {
         display: block;
        }
        .editPasswordBtn{
         margin: auto;
      }
  }
  @media screen and (max-width:1220px) {
      .profileImg {
          width: 230px;
          height: 230px;
         
        } 
  }
  @media screen and (max-width:1024px) {
      .profileImg {
          width: 200px;
          height: 200px;
         
        } 
  }
  @media screen and (max-width:768px) {
      .profileImg {
          width: 160px;
          height: 160px;
         
        } 
  }
  