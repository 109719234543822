.box {
  user-select: none;
}

.pdfBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 125px;
  border-radius: 10px;
  box-shadow: 0px 0 5px 2px #0000000d;
  position: relative;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.pdfBox .viewBtnBox {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  background-color: #54545447;
  height: 100%;
  z-index: 1;
  border-radius: inherit;
  opacity: 0;
  padding: 8px;
}

.pdfBox:hover .viewBtnBox {
  opacity: 1;
  transition: opacity 0.3s;
}

.typeIcon {
  font-size: 40px;
  color: var(--text-color-black);
}

.editAndDelete {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
}

.icon {
  background-color: var(--text-color-black);
  padding: 6px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #afa8a8;
  display: block;
}

.icon svg {
  color: var(--white-color);
  vertical-align: middle;
  width: 16px;
  height: 16px;
  display: inherit;
}

.uploadText {
  display: block;
  font-size: 14px;
  font-family: "Poppins-bold";
  text-transform: uppercase;
}
