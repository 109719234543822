.topContainer {
  height: calc(100vh - 122px);
  overflow-y: auto;
}

.main {
  /* padding-block: 20px; */
  max-width: 96% !important;
  padding-block: 20px;
}

.topStrip {
  background: var(--secondary-color);
  color: #f5e6e6;
  padding: 30px;
  display: grid;
  place-items: center;
}
