.main {
    /* padding-block: 20px; */
    max-width: 96% !important;
    padding-block: 20px
}


.tableMain {
    height: calc(100vh - 300px);
}

.row {
    row-gap: 20px;
}

.row h6 {
    font-family: 'Poppins-semiBold';
}

.viewDetailBtn {
    /* background: transparent linear-gradient(90deg, #E65D90 0%, #654ACB 100%) 0% 0% no-repeat padding-box; */
    border-radius: 46px;
    padding-block: 9px;
    width: 120px;
    /* width: 180px; */
}

.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.alignRight{
    display: flex;
    justify-content: end;
    align-items: center;
  }
.paginationDiv {
    display: flex;
    justify-content: flex-end;
}

.actions_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}