.packageMain {
  padding: 20px 30px;
  box-shadow: 0px 0px 10px 0px var(--primary-color);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.packageMain:hover {
  box-shadow: 0px 0px 20px 0px var(--primary-color);
  cursor: pointer;
  transform: translateY(-5px);
}

.distributionContainer {
  background-color: rgb(35, 34, 34);
  width: fit-content;
  color: var(--white-color);
  padding: 6px 19px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.distributionContainer span {
  font-size: 17px;
}

.packageMain h3 {
  font-size: 32px;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.perMonth {
}

.descriptions {
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  /* height: 403px; */
  overflow-y: auto;
}

.descriptionContainer::-webkit-scrollbar {
  display: none;
}

.descriptionItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.descriptionItem span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 17px;
}

.buttonContainer button {
  width: 100%;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}

.buttonContainer button:hover {
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
