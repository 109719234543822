.Container {
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-bottom: 2px;
}

.disabled {
  color: red;
}

.reactSelect {}

/* On Hover */
.dropdown:hover {
  border: 1px solid black !important;
}

/* on Focus */
.dropdown:focus {
  border: 1px solid black;
}

/* On Active */
.dropdown:active {
  border: 1px solid black;
}

/* On Disabled */
.dropdown:disabled {
  background-color: #f6f6f6;
  color: #b5b5b5;
  border: 1px solid #e5e5e5;
}

.noBorder {
  border: 0px solid #e5e5e5;
}

.arrow {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

/*  React Select */
.leftIconBox {
  position: absolute;
  left: 20px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

.dropdownContainer {
  position: relative;
}