.main {
  min-height: 50vh;
  max-height: 90vh;
  overflow: scroll;
}
.pdfContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.imageContainer {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.imageContainer img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.videoContainer {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.videoContainer video {
  width: 100%;
  max-height: 100%;
}

.closeBtn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent
    linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    0% 0% no-repeat padding-box;
  position: absolute;
  top: 2px;
  right: -35px;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
