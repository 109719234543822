.main {
  padding-block: 20px;
  max-width: 96% !important;
  height: calc(100vh - 121px);
  /* height: 800px; */
  overflow-y: scroll;
}

/* .content__wrapper {
    padding-block: 2rem;
    padding-inline: 3rem;
  } */
.packages__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.headerRight {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (max-width: 570px) {
  .headerRight {
    width: 100%;
  }
}

/* .packages__header h1 {
    font-size: var(--fs-h3-header-small);
    margin-inline-end: auto;
  } */
.packages__header .pricing__btn {
  color: var(--clr-secondary);
  background-color: transparent;
  font-family: var(--ff-rubik);
  border: 2px solid var(--clr-secondary);
  border-radius: 0.5rem;
  padding: 1rem;
}

.packages__header .pricing__btn.btn__active {
  background-color: var(--clr-secondary);
  border: 2px solid transparent;
  color: var(--clr-font-inverted);
}

.pricing__wrapper {
  padding-top: 3rem;
  /* display: flex; */
  /* flex-direction: column-reverse; */
  height: calc(100vh - 240px);
    overflow-y: scroll;
}

.loader {
  min-height: calc(100vh - 250px);
}

.alignRight{
  display: flex;
  justify-content: end;
  align-items: center;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
}