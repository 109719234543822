.topContainer {
  height: calc(100vh - 122px);
  overflow-y: auto;
}

.main {
  max-width: 96% !important;
  padding-block: 20px;
  padding-bottom: 50px;
}

.topStrip {
  background: var(--secondary-color);
  color: #f5e6e6;
  padding: 30px;
  display: grid;
  place-items: center;
  position: sticky;
  top: 0;
  z-index: 97;
}

.profileImageContainer {
  display: grid;
  place-items: center;
}

.customLabel {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-bottom: 2px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.buttonContainer button {
  width: 300px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.buttonContainer button:hover {
  box-shadow: 0px 0px 20px 0px #00000040;
  transform: translateY(-2px);
}
