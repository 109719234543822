.mainContainer {
  height: 100vh;
  width: 100%;
  /* background: var(--text-color-black); */
  background: transparent
    linear-gradient(
      178deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;

  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
  overflow-y: scroll;
}

.logoContainer {
  height: 110px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 30px;
  padding-block: 20px;
  border-bottom: 2px dotted white;
  margin-bottom: 20px;
}

.logoContainer img {
  width: 150px;
  cursor: pointer;
  height: auto;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 10px;
  padding-left: 20px;
  margin-block: 8px;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-family: "Poppins-medium";
  font-size: 16px;
  color: var(--white-color);
  margin-left: 18px;
}

.listItemContainer svg,
.innerItemContainer svg {
  color: var(--white-color);
}

::-webkit-scrollbar {
  display: none;
}

.innerItemContainer {
  padding-block: 10px;
  padding-left: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerItemContainer span {
  font-family: "Poppins-medium" !important;
  font-size: 14px;
  color: var(--white-color);
  margin-left: 18px;
}

.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
  fill: var(--white-color);
}

.subActive span {
  color: #ffffff;
  /* text-decoration: underline; */
}

.subActive svg {
  color: #ffffff !important;
}

.subActive .dropDownIcon {
  fill: #ffffff;
}

.subActive {
  margin-bottom: 10px !important;
}

.active,
.subActive {
  background: transparent linear-gradient(90deg, #cc919300 0%, #cc9193 100%) 0%
    0% no-repeat padding-box;
  border-right: 5px solid var(--white-color);
}

.active span {
  color: #ffffff;
}

.active svg {
  color: #ffffff !important;
}

.btn {
  padding: 15px 60px !important;
  margin: 7px 10px !important;
}
