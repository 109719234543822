.btn {
  outline: none;
  border: none;
  font-family: "Poppins-regular";
  padding: 15px 20px;
  line-height: 1;
  font-size: 16px;
  border-radius: 10px;
  background: transparent
    linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    0% 0% no-repeat padding-box;
  color: var(--white-color);
}

.btn[color-variant="yellow"] {
  background-color: var(--yellow-color);
  color: var(--white-color);
}

.btn[color-variant="white"] {
  background-color: var(--white-color);
  color: var(--btn-blue-color);
}
