.topContainer {
  height: calc(100vh - 122px);
  overflow-y: auto;
}

.main {
  max-width: 95% !important;
}

.mainHeading {
  margin-block: 15px;
  color: var(--primary-color);
  font-size: 29px;
}

.detailCard {
  box-shadow: 0px 0px 10px 0px var(--primary-color);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  height: 100%;
}

.profileImageContainer {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.profileImageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.singleItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.singleItem p {
  margin-bottom: 0;
  margin-left: 10px;
}

.acceptBtn {
  padding: 15px 50px;
}

.tableMain {
  height: calc(100vh - 300px);
}
