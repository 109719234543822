.Container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.labelText {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-bottom: 2px;
}

.inputBox {
  height: 100%;
  width: 100%;
}

.noBorder {
  border: none;
}

.inputPassContainer {
  display: flex;
  position: relative;
  background-color: var(--input-clr);
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  height: 100%;
}

.inputPassContainer input {
  font-family: "Poppins-semiBold";
  font-size: 16px;
  color: var(--text-color-black);
  /* letter-spacing: 1.4px; */
  border-radius: inherit;
  background-color: inherit;
}
.inputBox input {
  height: 100% !important;
  width: 100% !important;
  background-color: #efeeea !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.inputPassContainer input::placeholder {
  color: var(--placeholder-color);
  font-family: "Poppins-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.inputPassContainer .passwordIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #cacaca;
}

.errorText {
  color: red;
}

.leftIconBox {
  position: absolute;
  left: 20px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
/* input[type="number"] {
    -moz-appearance: textfield;
  } */

@media (max-width: 991px) {
  .labelText {
    font-size: 17px;
  }

  .inputBox {
    padding: 12px;
  }

  .inputPassContainer input {
    font-size: 15px;
  }
}
