.loginBtn {
    float: center;
    background-color: var(--footer-color-dark);
    color: var(--white-color);
    background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    min-width: 200px
}

.jCenter {
    display: flex;
    justify-content: center;
}

.row {
    row-gap: 20px;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header > h6{
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-bottom: 5px
}
.addMoreWrap{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
  }
  .cancel{
    position: absolute;
    right: 10px;
    bottom: 0%;
    transform: translate(-50%, -50%);
  }

  .desc{
    position: relative;
  }
.label{
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-left: 8px;
  /* width: 90px; */
}



