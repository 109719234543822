.studentMain {
  /* box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1); */
  border: 1px solid var(--secondary-color);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.imageContainer {
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.infoContainer {
  margin-top: 15px;
}

.infoContainer p {
  margin-bottom: 5px !important;
}

.infoContainer p b {
  width: 30%;
  display: inline-flex;
}
