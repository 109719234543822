.detail p:first-child{
    margin: 0px !important;
    font-weight: 700;
}
.btnWrap{
    display: flex;
    justify-content: center;
}
.payBtn{
    border-radius: 15px;
    padding-block: 14px;
    width: 200px;
  }
  


  /* are you sure */

  .content {
}

.content h4 {
  text-align: center;
  font-size: 25px;
}

.btnsBox {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.yesBtn {
  background-color: var(--text-color-black);
  padding: 10px 45px;
}

.noBtn {
  background-color: var(--text-color-black);
  padding: 10px 45px;
  margin-left: 40px;
}
  .message {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color-black);
  }
  
  .aCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;
    margin: auto;
  }