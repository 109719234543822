.main {
    max-width: 96% !important;
    padding-block: 20px;
  }
  
  .row{
    row-gap: 20px;
  }
  .tableMain {
    height: calc(100vh - 190px);
  }
  .paginationDiv{
    display: flex;
    justify-content: end;
    align-items: center;
  }