.datePicker_input {
  background: #f2f2f2;
  margin-bottom: 20px;
  height: 58px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 18px;
  position: relative;
}
.datePicker_input > div {
  width: 100%;
  height: 100%;
  display: flex;
}
.datePicker_input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.icon {
  position: absolute;
  right: -90%;
  width: 100% !important;
  top: 35%;
}

.icon svg {
  width: 20px;
  height: 20px;
}
.btn_main{
  margin-top: 10px;
}