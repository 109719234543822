.topContainer {
  height: calc(100vh - 122px);
  overflow-y: auto;
}

.main {
  max-width: 96% !important;
  padding-block: 20px;
}

.user_right_card {
box-shadow: 0px 0px 10px 0px var(--primary-color);;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;

  height: 100%;
}
.second_detail_row {
  align-items: end;
  margin-top: 40px;
}
.top_head_div {
  text-align: center;
}
.user_info {
  text-align: center;
}
.user_info h5 {
  text-transform: capitalize;
  margin-top: 10px;
}
.tableMain {
  margin-top: 50px;
  height: calc(100vh - 300px);
}
.user_left_card {
box-shadow: 0px 0px 10px 0px var(--primary-color);;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;

  height: 100%;
}
.user_left_card {
  text-align: center;
  min-height: 280px;
}
z
.img_wrapper {
  margin: auto;
  width: 150px;
  height: 150px;
}

.img_wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.singleItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.singleItem p {
  margin-bottom: 0;
  margin-left: 10px;
}


.mainHeading {
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 29px;
}


.classDetailContainer {
box-shadow: 0px 0px 10px 0px var(--primary-color);;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;

  height: 100%;
}