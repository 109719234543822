.materialCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: var(--white-color);
  box-shadow: 0px 0px 10px 0px var(--selection-clr);
}
.pdfIcon {
  height: 75px;
  width: 75px;
  color: var(--text-color);
}

.materialCardMain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: inherit;
  background-color: #000000;
}

.materialCardMain video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: inherit;
  background-color: #000000;
}

.deleteIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--primary-color);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.deleteIcon svg {
  color: var(--white-color);
  font-size: 18px;
}
