.main {
  /* padding-block: 20px; */
  max-width: 96% !important;
  padding-block: 20px;
}

.tableMain {
  height: calc(100vh - 270px);
}

.row {
  row-gap: 20px;
}

.row h6 {
  font-family: "Poppins-semiBold";
}

.viewDetailBtn {
  border-radius: 46px;
  padding-block: 14px;
  width: 140px;
}

.spBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alignRight{
  display: flex;
  justify-content: end;
  align-items: center;
}
.paginationDiv {
  display: flex;
  justify-content: flex-end;
}

.actions_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
